import React, { useState } from 'react';
import { TechniqueRequirement, GroupedTechniqueRequirements } from '../types/aikido';
import { TechniqueRequirementCard } from './TechniqueRequirementCard';

interface TechniqueRequirementsProps {
  requirements: TechniqueRequirement[];
}

type CategoryGroup = {
  [key: string]: TechniqueRequirement[];
};

export function TechniqueRequirements({ requirements }: TechniqueRequirementsProps) {
  // Start with 6e kyu selected
  const [selectedGrade, setSelectedGrade] = useState<string | null>("6e kyu (rokkyu)");

  // Group requirements by kyu grade
  const groupedRequirements = requirements.reduce<GroupedTechniqueRequirements>((acc, req) => {
    if (!acc[req.kyuGrade]) {
      acc[req.kyuGrade] = [];
    }
    acc[req.kyuGrade].push(req);
    return acc;
  }, {});

  const handleGradeClick = (grade: string) => {
    // If clicking the already selected grade, clear the selection to show all
    if (selectedGrade === grade) {
      setSelectedGrade(null);
    } else {
      setSelectedGrade(grade);
    }
  };

  // Function to group techniques by category
  const groupByCategory = (techniques: TechniqueRequirement[]): CategoryGroup => {
    return techniques.reduce<CategoryGroup>((acc, technique) => {
      const category = technique.category || 'Overig';
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(technique);
      return acc;
    }, {});
  };

  // Define the desired category order
  const categoryOrder = ['Suwariwaza', 'Hanmihandachi', 'Tachiwaza', 'Bukiwaza', 'Jiuwaza', 'Overig'];

  return (
    <div className="container mx-auto px-4 py-8">
      {/* Grade selection tabs */}
      <div className="flex flex-wrap gap-2 mb-8">
        {Object.keys(groupedRequirements).map((grade) => (
          <button
            key={grade}
            onClick={() => handleGradeClick(grade)}
            className={`px-4 py-2 rounded-lg font-medium transition-colors
              ${selectedGrade === grade
                ? 'bg-[#411217] text-white'
                : 'bg-gray-100 text-gray-900 hover:bg-gray-200'
              }`}
          >
            {grade}
          </button>
        ))}
      </div>

      {/* Display requirements for selected grade or all grades */}
      <div className="space-y-12">
        {Object.entries(groupedRequirements)
          .filter(([grade]) => !selectedGrade || grade === selectedGrade)
          .map(([grade, techniques]) => {
            const categorizedTechniques = groupByCategory(techniques);
            const activeCategories = categoryOrder.filter(
              category => categorizedTechniques[category]?.length
            );

            return (
              <div key={grade} className="space-y-8">
                <div className="flex items-center justify-between">
                  <h2 className="text-2xl font-bold text-gray-900">{grade}</h2>
                  <span className="text-gray-600">
                    Richtlijn aantal lessen sinds vorige examen: {techniques[0].requiredLessons}
                  </span>
                </div>

                {/* Display techniques grouped by category vertically */}
                <div className="space-y-8">
                  {activeCategories.map(category => (
                    <div key={category}>
                      <h3 className="text-xl font-semibold text-gray-800 pb-2 mb-4 border-b border-gray-200">
                        {category}
                      </h3>
                      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {categorizedTechniques[category].map((technique, index) => (
                          <TechniqueRequirementCard
                            key={`${grade}-${category}-${index}`}
                            technique={technique}
                          />
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
} 