import * as React from "react"

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: "default" | "secondary" | "outline"
}

export function Badge({ 
  className, 
  variant = "default", 
  ...props 
}: BadgeProps) {
  const variantClasses = {
    default: "bg-[#411217] text-white hover:bg-[#2a0b0e]",
    secondary: "bg-gray-100 text-gray-900 hover:bg-gray-200",
    outline: "border border-[#411217] text-[#411217] hover:bg-[#411217] hover:text-white"
  }

  return (
    <div
      className={`
        inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-semibold transition-colors
        ${variantClasses[variant]}
        ${className}
      `}
      {...props}
    />
  )
} 