import React, { useEffect, useState } from 'react';
import { TechniqueRequirement } from '../types/aikido';
import { TechniqueRequirements } from '../components/TechniqueRequirements';
import { parseCSV } from '../utils/csvParser';

export function KyuRequirementsPage() {
  const [requirements, setRequirements] = useState<TechniqueRequirement[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    async function loadRequirements() {
      try {
        const response = await fetch('/data/csv/kyu-requirements.csv');
        if (!response.ok) {
          throw new Error('Failed to load requirements data');
        }
        const csvContent = await response.text();
        const parsedRequirements = parseCSV(csvContent);
        setRequirements(parsedRequirements);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred while loading the requirements');
      } finally {
        setIsLoading(false);
      }
    }

    loadRequirements();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-[#411217]"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto px-4 py-8">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">Kyu Graad Vereisten</h1>
        <TechniqueRequirements requirements={requirements} />
      </div>
    </div>
  );
}
