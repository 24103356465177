import React from 'react';
import { Link } from 'react-router-dom';
import Events from '../components/Events';

const events = [
  {
    id: 2,
    title: 'Jan Nevelius Seminar',
    date: '13-15 december 2024',
    description: 'Driedaags seminar onder leiding van Jan Nevelius Sensei (7e dan)',
    location: 'Valkenhuizen',
    time: '',
    image: 'https://images.unsplash.com/photo-1507525428034-b723cf961d3e?q=80&w=2073&auto=format&fit=crop'
  },
  {
    id: 3,
    title: 'Laatste Training & Lunch',
    date: '27 december 2024',
    description: 'Laatste training van het jaar met aansluitend een gezellige lunch',
    location: 'Dojo Anshin Aikido Arnhem',
    time: 'Reguliere trainingstijd',
    image: 'https://images.unsplash.com/photo-1531973819741-e27a5ae2cc7b?q=80&w=2940&auto=format&fit=crop'
  },
  {
    id: 4,
    title: 'Kagami Biraki',
    date: '7 januari 2025',
    description: 'Traditionele Japanse nieuwjaarsceremonie',
    location: 'Dojo Anshin Aikido Arnhem',
    time: '',
    image: 'https://images.unsplash.com/photo-1519751138087-5bf79df62d5b?q=80&w=2070&auto=format&fit=crop'
  },
  {
    id: 5,
    title: 'Patrick Cassidy Seminar',
    date: '31 januari - 2 februari 2025',
    description: 'Driedaags seminar onder leiding van Patrick Cassidy Sensei (7e dan EAC)',
    location: 'Alpen',
    time: '',
    image: 'https://images.unsplash.com/photo-1464207687429-7505649dae38?q=80&w=2073&auto=format&fit=crop'
  }
];

export default function Home() {
  return (
    <div className="pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 lg:gap-12">
          {/* Linker kolom met welkomsttekst */}
          <div className="lg:col-span-7 space-y-6 sm:space-y-8">
            <div>
              <h1 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 sm:mb-6">
                Welkom bij Anshin, Aikido Vereniging Arnhem!
              </h1>
              
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Wat is Aikido?</h2>
              <p className="text-gray-600 leading-relaxed mb-6">
                Aikido is een Japanse krijgskunst die begin 20e eeuw is ontwikkeld door O'Sensei Morihei Ueshiba. Hij had achtergrond in verschillende krijgskunsten waaronder jiu jitsu, speervechten en zwaardvechten, maar vond daar geen voldoening in. Hij had de behoefte de strijd van alsmaar 'winnen' versus 'verliezen' te overstijgen. O'sensei zei daarover: "als er een winnaar is, is er een verliezer, en als er een verliezer is, is de strijd nooit voorbij." Om een conflict echt effectief op te lossen, laat Aikido daarom geen geweld toe: niet naar jezelf, maar ook niet naar de ander.
              </p>
              <Link to="/achtergrond" className="link-primary">
                Benieuwd naar meer achtergrond? Klik hier →
              </Link>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Anshin</h2>
              <p className="text-gray-600 leading-relaxed mb-6">
                'Shin' betekent hart, en 'Anshin' betekent gemoedsrust, vertrouwen. Het vinden van vertrouwen in jezelf, in de beweging, en uiteindelijk in het leven. Dat klinkt heel rustgevend, maar er gaat ook een enorme kracht vanuit. De kracht van een kalm hart, en van daaruit elke beweging van de ander ontvangen zonder je balans te verliezen. Zowel fysiek als mentaal, want conflictoplossend vermogen overstijgt de mat, en kan je in meer vlakken van je leven toepassen.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-semibold text-gray-800 mb-4">Aikidovereniging</h2>
              <p className="text-gray-600 leading-relaxed mb-4">
                Wij zijn bewust een vereniging, omdat Aikido verbindt. We zijn een gemeenschap die naast te trainen het ook leuk vindt om activiteiten te organiseren die verbinden. We borgen daarvoor een veilig leerklimaat waarin ieder zichzelf kan ontplooien. Benieuwd naar ons lesaanbod? Kijk hier of neem contact op via info@anshin-aikido.nl
              </p>
              <div className="flex items-center gap-4">
                <Link to="/lesaanbod" className="link-primary">
                  Bekijk ons lesaanbod →
                </Link>
                <span className="text-gray-400">of</span>
                <a href="mailto:info@anshin-aikido.nl" className="link-primary">
                  neem contact op
                </a>
              </div>
            </div>
          </div>

          {/* Rechter kolom met evenementen */}
          <div className="lg:col-span-5 lg:sticky lg:top-24">
            <Events events={events} />
          </div>
        </div>
      </div>
    </div>
  );
}