import axios from 'axios';

export interface PastEvent {
  id: string;
  title: string;
  date: string;
  description: string;
  location: string;
  image: string;
}

// Sheet IDs voor verschillende omgevingen
const SHEET_CONFIG = {
  development: {
    id: '1Ol8UqcOWUPetCpM5TKye0h8J0EPWvsDBimwZYBujvOA',
    name: 'Form Responses 1'
  },
  production: {
    id: '1ecE0Al1xjSuPtNrqrX3N1xXlCJgGiYDKq455zwfMDFQ',
    name: 'Form Responses 1'
  }
};

// Bepaal de huidige omgeving
const ENV = process.env.NODE_ENV || 'development';

// Selecteer de juiste sheet configuratie
const SHEET_ID = SHEET_CONFIG[ENV as keyof typeof SHEET_CONFIG].id;
const SHEET_NAME = SHEET_CONFIG[ENV as keyof typeof SHEET_CONFIG].name;

function formatDate(dateString: string): string {
  try {
    if (dateString.startsWith('Date(')) {
      const parts = dateString
        .replace('Date(', '')
        .replace(')', '')
        .split(',')
        .map(Number);
      
      const date = new Date(parts[0], parts[1], parts[2]);
      
      return date.toLocaleDateString('nl-NL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
    }

    // Als het geen Date() string is, probeer het als normale datum te parsen
    const date = new Date(dateString);
    if (!isNaN(date.getTime())) {
      return date.toLocaleDateString('nl-NL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric'
      });
    }

    return dateString;
  } catch (error) {
    console.error('Error formatting date:', error);
    return dateString;
  }
}

function getImageUrl(url: string): string {
  try {
    if (url.startsWith('https://images.unsplash.com/')) {
      return url;
    }
    if (url.includes('unsplash.com/photos/')) {
      return 'https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan';
    }
    if (!url) {
      return 'https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan';
    }
    return url;
  } catch (error) {
    console.error('Error processing image URL:', error);
    return 'https://source.unsplash.com/random/800x600/?aikido,martial-arts,japan';
  }
}

export async function fetchPastEvents(): Promise<PastEvent[]> {
  try {
    console.log(`Using ${ENV} sheet configuration for past events:`, { SHEET_ID, SHEET_NAME });
    
    const response = await axios.get(
      `https://docs.google.com/spreadsheets/d/${SHEET_ID}/gviz/tq?tqx=out:json&sheet=${SHEET_NAME}`
    );

    const data = JSON.parse(response.data.substring(47).slice(0, -2));
    console.log('Raw Google Sheets data for past events:', data.table.rows);
    
    if (!data.table.rows) return [];

    return data.table.rows
      .filter((row: any) => row.c[1]?.v) // Filter op titel
      .map((row: any, index: number) => {
        const dateStr = row.c[2]?.v || '';
        console.log('Processing past event row:', row);
        console.log('Date string:', dateStr);
        
        return {
          id: index.toString(),
          title: row.c[1]?.v || '',
          date: formatDate(dateStr),
          description: row.c[3]?.v || '',
          location: row.c[4]?.v || '',
          image: getImageUrl(row.c[5]?.v || '')
        };
      });
  } catch (error) {
    console.error('Error fetching past events:', error);
    throw new Error('Er is een probleem opgetreden bij het ophalen van de eerdere evenementen. Probeer het later opnieuw.');
  }
}
