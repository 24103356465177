import React from 'react';
import { Calendar, Clock, MapPin, Link as LinkIcon } from 'lucide-react';

interface EventCardProps {
  title: string;
  date: string;
  description: string;
  imageUrl: string;
  location?: string;
  time?: string;
  eventLink?: string;
}

export default function EventCard({ 
  title, 
  date, 
  description, 
  imageUrl,
  location,
  time,
  eventLink,
}: EventCardProps) {
  const getImageUrl = (filename: string) => {
    // Als het een volledige URL is (begint met http of https), gebruik deze direct
    if (filename.startsWith('http://') || filename.startsWith('https://')) {
      return filename;
    }
    // Anders, bouw een relatieve URL op naar de images folder
    return `/images/${filename}`;
  };

  return (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
      <div className="flex flex-col">
        {/* Header met titel en datum/tijd */}
        <div className="p-4">
          <h3 className="text-xl font-semibold">{title}</h3>
          <div className="flex items-center text-gray-600 mt-1">
            <Calendar className="w-4 h-4 mr-2" />
            <span>{date}</span>
            {time && (
              <>
                <Clock className="w-4 h-4 ml-4 mr-2" />
                <span>{time}</span>
              </>
            )}
          </div>
        </div>

        {/* Afbeelding */}
        <div className="relative h-72">
          {imageUrl ? (
            <img 
              src={getImageUrl(imageUrl)} 
              alt={title} 
              className="absolute inset-0 w-full h-full object-cover"
              onError={(e) => {
                console.log('Image failed to load:', imageUrl);
                const target = e.target as HTMLImageElement;
                target.src = '/images/default-event.jpg';
              }}
            />
          ) : (
            <div className="w-full h-full bg-gray-200 flex items-center justify-center">
              <span className="text-gray-400">Geen afbeelding beschikbaar</span>
            </div>
          )}
        </div>

        {/* Content */}
        <div className="p-6 pt-2">
          {location && (
            <div className="flex items-center text-gray-600 mb-4">
              <MapPin className="w-4 h-4 mr-2" />
              <span>{location}</span>
            </div>
          )}
          <p className="text-gray-600 mb-4">{description}</p>
          {eventLink && (
            <a 
              href={eventLink}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center text-primary-600 hover:text-primary-700"
            >
              <LinkIcon className="w-4 h-4 mr-2" />
              Meer informatie
            </a>
          )}
        </div>
      </div>
    </div>
  );
}