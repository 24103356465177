import React from 'react';
import { useEffect, useState } from 'react';
import { fetchEvents } from '../services/eventService';
import EventCard from './EventCard';
import { Event } from '../services/eventService';

export default function Events() {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const fetchedEvents = await fetchEvents();
        setEvents(fetchedEvents);
        setLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err.message : 'Er is een fout opgetreden');
        setLoading(false);
      }
    };

    loadEvents();
  }, []);

  if (loading) {
    return <div className="text-center p-4">Laden...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-4">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Aankomende evenementen</h1>
      <p className="text-gray-600 mb-8">
        Bekijk onze speciale trainingen, workshops en evenementen. 
        Schrijf je in of neem contact op voor meer informatie.
      </p>
      <div className="max-w-4xl mx-auto">
        <div className="space-y-8">
          {events.map((event, index) => (
            <EventCard
              key={event.id}
              isFirst={index === 0}
              title={event.title}
              date={event.date}
              description={event.description}
              imageUrl={event.image}
              location={event.location}
              time={event.time}
              eventLink={event.eventLink}
            />
          ))}
        </div>
      </div>
    </div>
  );
}