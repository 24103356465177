import { TechniqueRequirement } from "../types/aikido";

export function parseCSV(csvContent: string): TechniqueRequirement[] {
  const lines = csvContent.split("\n");

  return lines
    .slice(1) // Skip header row
    .filter((line) => line.trim()) // Skip empty lines
    .map((line) => {
      const values = line.split(";");
      return {
        kyuGrade: values[0]?.trim() || "",
        requiredLessons: parseInt(values[1]?.trim() || "0", 10),
        category: values[2]?.trim() || "",
        attack: values[3]?.trim() || "",
        technique: values[4]?.trim() || "",
        variation: values[5]?.trim() || "",
        practiceType: values[6]?.trim() || "",
        youtubeUrl: values[7]?.trim() || "",
      };
    });
}
