import React from 'react';

export default function Docent() {
  return (
    <div className="pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
          {/* Linker kolom met foto's */}
          <div className="lg:col-span-4 space-y-8">
            <div>
              <img
                src="/images/Inward.jpg"
                alt="Rinske Gotink in actie"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            <div>
              <img
                src="/images/Sandancertificate.jpg"
                alt="Sandan (3e dan) certificaat"
                className="w-full rounded-lg shadow-lg"
              />
            </div>
            <div>
              <img
                src="/images/KokyumetPasi.jpg"
                alt="Kokyu training met Pasi"
                className="w-full max-w-sm mx-auto rounded-lg shadow-lg"
              />
            </div>
          </div>

          {/* Rechter kolom met tekst en extra foto */}
          <div className="lg:col-span-8 space-y-8">
            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">Rinske Gotink (1989), 3e dan</h2>
              <p className="text-gray-600 leading-relaxed mb-6">
                Mijn naam is Rinske Gotink, geboren in Arnhem. Toen ik ging studeren in Maastricht, kwam ik bij de dojo van Rob Vinken terecht. Hier waren aikido, meditatie en zelfontplooiing verweven en waren de trainingen energiek en gevarieerd. Ik heb hier ook Miles Kessler en Patrick Cassidy ontmoet, die er frequent weekendstages gaven. Na mijn studie heb ik drie maanden intern bij Miles Kessler in Israël getraind, en daarna onder hem mijn 1e dan gehaald in Maastricht. Hierna begon mijn reis langs meerdere dojo's (Haarlem, Amsterdam, Den Haag, Rotterdam) waar ik heb meegenomen wat resoneerde, en geleerd van wat confronteerde. Mijn 2e dan heb ik in 2017 verkregen bij Patrick Cassidy, 7e dan, in Zwitserland. Hierna ben ik in Arnhem gaan lesgeven bij Aikido aan de Rijn, maar ik stopte niet met trainen. In 2023 heb ik mijn 3e dan graad gehaald, tevens bij Patrick Cassidy. Na zes jaar bij Aikido aan de Rijn werd het tijd voor mijn eigen dojo.
              </p>
            </section>

            <section>
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">Aikido biedt wat je erin zoekt</h2>
              <p className="text-gray-600 leading-relaxed mb-6">
                Wat me aanspreekt in aikido en wat ik graag wil delen met anderen is het geweldloze karakter: hoe kan je geweld tegen jezelf voorkomen, zonder de ander te beschadigen? Hoe reageer je als je onder druk wordt gezet? Hoe blijf je overeind zonder dat je jezelf of je principes geweld aandoet? Dat er geen kracht wordt gebruikt vind ik een extra voordeel (tegen een boom van een vent begin ik qua kracht immers niet veel). Maar bovenal ontstijgt aikido het winnen-verliezen idee. O'Sensei zei al: "als er een winnaar is, is er een verliezer. En als er een verliezer is, is de strijd niet voorbij." Ik ben van nature erg competitief ingesteld, en dit vormt voor mij de ultieme uitdaging. Aikido biedt wat je erin zoekt. Het kan een intensieve sport zijn waarin je je conditie en flexibiliteit traint, maar het kan evengoed in alle rust en evenwicht beoefend worden.
              </p>
            </section>

            <section>
              <p className="text-gray-600 leading-relaxed mb-6">
                In mijn lessen wil ik mijn passie voor verbinden, bewegen, op je lichaam durven vertrouwen, en jezelf leren kennen met je delen. Ik zal hiervoor naast mijn technische kennis ook mijn ervaring als mindfulnesstrainer gebruiken, maar ook Iwama wapenwerk, en jiu waza (vrij bewegen) op muziek. Aikido heeft mij ontzettend veel moois gebracht en ik gun dat iedereen. Tot op de mat!!
              </p>
            </section>

            {/* Kotegaeshi foto onder de tekst */}
            <div className="flex justify-center">
              <img
                src="/images/Kotegaeshi.jpg"
                alt="Kotegaeshi techniek"
                className="w-3/4 rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
