import React, { useState } from 'react';
import { TechniqueRequirement } from '../types/aikido';
import { Card, CardContent, CardHeader } from './ui/card';
import { Badge } from './ui/badge';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface TechniqueRequirementCardProps {
  technique: TechniqueRequirement;
}

export function TechniqueRequirementCard({ technique }: TechniqueRequirementCardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const hasVideo = Boolean(technique.youtubeUrl?.trim());

  const getEmbedUrl = (url: string): string => {
    try {
      let videoId = '';
      
      if (url.includes('youtu.be/')) {
        // Handle youtu.be links
        videoId = url.split('youtu.be/')[1].split('?')[0];
      } else if (url.includes('youtube.com/watch')) {
        // Handle youtube.com links
        const urlParams = new URLSearchParams(url.split('?')[1]);
        videoId = urlParams.get('v') || '';
      }
      
      if (!videoId) return '';
      
      return `https://www.youtube.com/embed/${videoId}`;
    } catch (error) {
      console.error('Error processing YouTube URL:', error);
      return '';
    }
  };

  const toggleExpand = () => {
    if (hasVideo) {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <Card className="w-full hover:shadow-lg transition-shadow duration-200">
      <CardHeader 
        className={`flex flex-row items-center justify-between ${hasVideo ? 'cursor-pointer' : ''}`}
        onClick={toggleExpand}
      >
        <div className="flex flex-col space-y-2">
          <div className="space-y-1">
            {technique.attack && technique.attack !== '-' && (
              <div className="text-sm text-gray-500 font-medium">
                Aanval: <span className="text-gray-700">{technique.attack}</span>
              </div>
            )}
            <h3 className="text-lg font-semibold text-gray-900">
              {technique.technique}
            </h3>
          </div>
          <div className="flex flex-wrap gap-2">
            {technique.variation && (
              <Badge variant="outline">{technique.variation}</Badge>
            )}
            {technique.practiceType && (
              <Badge variant="default">{technique.practiceType}</Badge>
            )}
          </div>
        </div>
        {hasVideo && (
          isExpanded ? <ChevronUp className="h-6 w-6" /> : <ChevronDown className="h-6 w-6" />
        )}
      </CardHeader>
      
      {isExpanded && technique.youtubeUrl && (
        <CardContent className="pt-4">
          <div className="relative pb-[56.25%] h-0">
            <iframe
              src={getEmbedUrl(technique.youtubeUrl)}
              title={`${technique.attack} ${technique.technique}`}
              className="absolute top-0 left-0 w-full h-full rounded-lg"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </CardContent>
      )}
    </Card>
  );
} 