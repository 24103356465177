import React from 'react';
import { Link } from 'react-router-dom';

export default function Practical() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16 mt-10">
      <h1 className="text-4xl font-bold mb-8">Praktische Informatie</h1>
      
      <div className="space-y-12">
        {/* Lesrooster Sectie */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Lesrooster</h2>
          <div className="bg-white rounded-lg shadow p-6">
            <div className="space-y-3">
              <p><strong>Dinsdag:</strong> 20:00 - 21:30 Algemene training</p>
              <p><strong>Zondag:</strong> 10:30 - 12:00 Algemene training</p>
            </div>
          </div>
        </section>

        {/* Tarieven Sectie */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Tarieven</h2>
          <div className="bg-white rounded-lg shadow p-6">
            <div className="space-y-3">
              <p><strong>Maandelijks lidmaatschap:</strong> €35,-</p>
              <p><strong>Strippenkaart:</strong> €30 voor 4 lessen, drie maanden geldig</p>
              <p><strong>Proefles:</strong> Gratis</p>
            </div>
          </div>
        </section>

        {/* Exameneisen Sectie */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Exameneisen</h2>
          <div className="bg-white rounded-lg shadow p-6">
            <p className="mb-4">
              Voor het behalen van een kyu-graad zijn er specifieke exameneisen opgesteld. 
              Deze eisen helpen je om gericht te trainen en je voor te bereiden op je examen.
            </p>
            <Link 
              to="/kyu-requirements" 
              className="inline-block bg-[#411217] text-white px-4 py-2 rounded hover:bg-[#2a0b0e] transition-colors"
            >
              Bekijk Exameneisen
            </Link>
          </div>
        </section>

        {/* Huisregels Sectie */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Huisregels</h2>
          <div className="bg-white rounded-lg shadow p-6">
            <ul className="list-disc list-inside space-y-2">
              <li>Kom op tijd voor de les</li>
              <li>Zorg voor persoonlijke hygiëne</li>
              <li>Draag kleding waar je gemakkelijk in kunt bewegen</li>
              <li>Respecteer je trainingspartner</li>
              <li>Volg de aanwijzingen van de leraar</li>
            </ul>
          </div>
        </section>

        {/* FAQ Sectie */}
        <section>
          <h2 className="text-2xl font-semibold mb-4">Veel gestelde vragen</h2>
          <div className="space-y-4">
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="font-semibold mb-2">Wat moet ik meenemen naar de eerste les?</h3>
              <p className="text-gray-600">Gemakkelijk zittende sportkleding, slippers en een flesje water. Een aikidopak is pas nodig als je echt wilt beginnen.</p>
            </div>
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="font-semibold mb-2">Hoe lang duurt een training?</h3>
              <p className="text-gray-600">Een reguliere training duurt 90 minuten, inclusief warming-up en cooling-down.</p>
            </div>
            <div className="bg-white rounded-lg shadow p-6">
              <h3 className="font-semibold mb-2">Vanaf welke leeftijd kan ik beginnen?</h3>
              <p className="text-gray-600">Onze trainingen zijn geschikt voor mensen vanaf 16 jaar. Er is geen maximum leeftijd.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
